import * as React from "react";
import Redirect from "../../components/redirect";
//--------------------------------------------------

  // this view shows all projects
  const url = "https://app.instagantt.com/shared/s/tfFbBsulPZGucMUBYj1x/latest";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;
